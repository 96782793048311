// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

@import "@ionic/angular/css/core.css";

/** Ionic CSS Variables **/
:root {
  --pa-course-min-width: 12rem;

  --pa-font-size-xxl: 2.4rem;
  --pa-font-size-xl: 1.6rem;
  --pa-font-size-l: 1.2rem;
  --pa-font-size-m: 1rem;
  --pa-font-size-s: 0.875rem;
  --pa-font-size-xs: 0.8rem;
  --pa-font-size-icon: 1.5rem;
  --pa-font-size-studypoints: 0.9rem;

  --pa-color-primary: #404040;
  --pa-color-secondary: var(--pa-color-primary-600);
  --pa-color-green: #5ea801;
  --pa-color-red: #d60270;
  --pa-color-orange: #ef8025;
  --pa-color-blue: #006fe6;
  --pa-color-draft: #ccd941;
  --pa-color-light: --pa-color-primary-900;

  // let the disabled opacity be dark enough to satisfy the accessibility contrast requirements
  --pa-opacity-disabled: 0.54;

  // Functional PlanApp colors:
  --pa-color-action-button: var(--pa-color-primary-600);
  --pa-color-action-hover: color-mix(in srgb, var(--pa-grid-color-actions), white 90%);
  --pa-error-color: var(--pa-color-red);
  --pa-error-bgcolor: var(--pa-color-red-850);
  --pa-grid-color-header: var(--pa-color-primary-600);
  --pa-grid-color-year: var(--pa-color-primary-900);
  --pa-grid-color-period-not-actual: var(--pa-color-primary-800);
  --pa-grid-color-period-actual: var(--pa-color-primary-900);
  --pa-grid-color-course-hover: var(--pa-color-primary-900);
  --pa-grid-color-course-obtained: var(--pa-color-primary-850);
  --pa-grid-color-course-obtained-icon: var(--pa-color-green);
  --pa-grid-color-course-not-obtained-icon: var(--pa-color-red);
  --pa-grid-color-course-test: var(--pa-color-blue);
  --pa-grid-color-actions: var(--pa-color-green);
  --pa-grid-color-timeslot: var(--pa-color-green);
  --pa-grid-color-alert: var(--pa-color-orange);
  --pa-grid-color-points: var(--pa-color-primary-300);
  --pa-grid-color-exam-component: var(--pa-color-primary-300);
  --pa-grid-color-note: var(--pa-color-primary-300);
  --pa-color-results: var(--pa-color-primary-800);
  --pa-color-results-header: var(--pa-color-primary-400);
  --pa-grid-color-group-not-chosen: var(--pa-color-primary-500);

  --pa-color-primary-50: color-mix(in srgb, var(--pa-color-primary), white 5%);
  --pa-color-primary-100: color-mix(in srgb, var(--pa-color-primary), white 10%);
  --pa-color-primary-150: color-mix(in srgb, var(--pa-color-primary), white 15%);
  --pa-color-primary-200: color-mix(in srgb, var(--pa-color-primary), white 20%);
  --pa-color-primary-250: color-mix(in srgb, var(--pa-color-primary), white 25%);
  --pa-color-primary-300: color-mix(in srgb, var(--pa-color-primary), white 30%);
  --pa-color-primary-350: color-mix(in srgb, var(--pa-color-primary), white 35%);
  --pa-color-primary-400: color-mix(in srgb, var(--pa-color-primary), white 40%);
  --pa-color-primary-450: color-mix(in srgb, var(--pa-color-primary), white 45%);
  --pa-color-primary-500: color-mix(in srgb, var(--pa-color-primary), white 50%);
  --pa-color-primary-550: color-mix(in srgb, var(--pa-color-primary), white 55%);
  --pa-color-primary-600: color-mix(in srgb, var(--pa-color-primary), white 60%);
  --pa-color-primary-650: color-mix(in srgb, var(--pa-color-primary), white 65%);
  --pa-color-primary-700: color-mix(in srgb, var(--pa-color-primary), white 70%);
  --pa-color-primary-750: color-mix(in srgb, var(--pa-color-primary), white 75%);
  --pa-color-primary-800: color-mix(in srgb, var(--pa-color-primary), white 80%);
  --pa-color-primary-850: color-mix(in srgb, var(--pa-color-primary), white 85%);
  --pa-color-primary-900: color-mix(in srgb, var(--pa-color-primary), white 90%);
  --pa-color-primary-950: color-mix(in srgb, var(--pa-color-primary), white 95%);

  --pa-color-red-50: color-mix(in srgb, var(--pa-color-red), white 5%);
  --pa-color-red-100: color-mix(in srgb, var(--pa-color-red), white 10%);
  --pa-color-red-150: color-mix(in srgb, var(--pa-color-red), white 15%);
  --pa-color-red-200: color-mix(in srgb, var(--pa-color-red), white 20%);
  --pa-color-red-250: color-mix(in srgb, var(--pa-color-red), white 25%);
  --pa-color-red-300: color-mix(in srgb, var(--pa-color-red), white 30%);
  --pa-color-red-350: color-mix(in srgb, var(--pa-color-red), white 35%);
  --pa-color-red-400: color-mix(in srgb, var(--pa-color-red), white 40%);
  --pa-color-red-450: color-mix(in srgb, var(--pa-color-red), white 45%);
  --pa-color-red-500: color-mix(in srgb, var(--pa-color-red), white 50%);
  --pa-color-red-550: color-mix(in srgb, var(--pa-color-red), white 55%);
  --pa-color-red-600: color-mix(in srgb, var(--pa-color-red), white 60%);
  --pa-color-red-650: color-mix(in srgb, var(--pa-color-red), white 65%);
  --pa-color-red-700: color-mix(in srgb, var(--pa-color-red), white 70%);
  --pa-color-red-750: color-mix(in srgb, var(--pa-color-red), white 75%);
  --pa-color-red-800: color-mix(in srgb, var(--pa-color-red), white 80%);
  --pa-color-red-850: color-mix(in srgb, var(--pa-color-red), white 85%);
  --pa-color-red-900: color-mix(in srgb, var(--pa-color-red), white 90%);
  --pa-color-red-950: color-mix(in srgb, var(--pa-color-red), white 95%);

  --ion-color-primary: var(--pa-color-primary);
  --ion-color-primary-rgb: 64, 64, 64;
  --ion-color-primary-contrast: white;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: color-mix(in srgb, var(--pa-color-primary), black 10%);
  --ion-color-primary-tint: color-mix(in srgb, var(--pa-color-primary), white 10%);

  --ion-color-secondary: var(--pa-color-secondary);
  --ion-color-secondary-rgb: color-mix(in srgb, var(--pa-color-secondary) 100%, white);
  --ion-color-secondary-contrast: white;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: color-mix(in srgb, var(--pa-color-secondary), black 10%);
  --ion-color-secondary-tint: color-mix(in srgb, var(--pa-color-secondary), white 20%);

  --ion-color-tertiary: var(--ion-color-tertiary);
  --ion-color-tertiary-rgb: color-mix(in srgb, var(--ion-color-tertiary) 100%, white);
  --ion-color-tertiary-contrast: white;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: color-mix(in srgb, var(--ion-color-tertiary), black 10%);
  --ion-color-tertiary-tint: color-mix(in srgb, var(--ion-color-tertiary), white 10%);

  --ion-color-success: var(--pa-color-green);
  --ion-color-success-rgb: color-mix(in srgb, var(--ion-color-success) 100%, white);
  --ion-color-success-contrast: white;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: color-mix(in srgb, var(--ion-color-success), black 10%);
  --ion-color-success-tint: color-mix(in srgb, var(--ion-color-success), white 10%);

  --ion-color-warning: var(--pa-color-draft);
  --ion-color-warning-rgb: color-mix(in srgb, var(--ion-color-warning) 100%, white);
  --ion-color-warning-contrast: var(--pa-color-primary);
  --ion-color-warning-contrast-rgb: var(--ion-color-primary-rgb);
  --ion-color-warning-shade: color-mix(in srgb, var(--ion-color-warning), black 10%);
  --ion-color-warning-tint: color-mix(in srgb, var(--ion-color-warning), white 20%);

  --ion-color-danger: var(--pa-color-red);
  --ion-color-danger-rgb: color-mix(in srgb, var(--ion-color-danger) 100%, white);
  --ion-color-danger-contrast: white;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: color-mix(in srgb, var(--ion-color-danger), black 10%);
  --ion-color-danger-tint: color-mix(in srgb, var(--ion-color-danger), white 20%);

  --ion-color-dark: var(--pa-color-primary);
  --ion-color-dark-rgb: color-mix(in srgb, var(--ion-color-dark) 100%, white);
  --ion-color-dark-contrast: white;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: color-mix(in srgb, var(--ion-color-dark), black 10%);
  --ion-color-dark-tint: color-mix(in srgb, var(--ion-color-dark), white 10%);

  --ion-color-medium: var(--pa-color-primary-300);
  --ion-color-medium-rgb: color-mix(in srgb, var(--ion-color-medium) 100%, white);
  --ion-color-medium-contrast: white;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: color-mix(in srgb, var(--ion-color-medium), black 10%);
  --ion-color-medium-tint: color-mix(in srgb, var(--ion-color-medium), white 10%);

  --ion-color-light: var(--pa-color-primary-900);
  --ion-color-light-rgb: color-mix(in srgb, var(--ion-color-light) 100%, white);
  --ion-color-light-contrast: var(--pa-color-primary);
  --ion-color-light-contrast-rgb: var(--ion-color-primary-rgb);
  --ion-color-light-shade: color-mix(in srgb, var(--ion-color-light), black 10%);
  --ion-color-light-tint: color-mix(in srgb, var(--ion-color-light), white 50%);

  --ion-color-white: white;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: var(--pa-color-primary);
  --ion-color-white-contrast-rgb: var(--ion-color-primary-rgb);
  --ion-color-white-shade: color-mix(in srgb, white, black 10%);
  --ion-color-white-tint: color-mix(in srgb, white, black 10%);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

html {
  /*
   * For more information on dynamic font scaling, visit the documentation:
   * https://ionicframework.com/docs/layout/dynamic-font-scaling
   */
  --ion-dynamic-font: var(--ion-default-dynamic-font);
}
