/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

html {
  --ion-dynamic-font: var(--ion-default-dynamic-font);
  --ion-font-family: "Roboto", sans-serif;
  font-size: var(--pa-font-size-s);
  font-weight: 400;
}

ion-title {
  font-size: var(--pa-font-size-l);
  font-weight: 500;
}

ion-modal {
  --min-height: 20rem;
  --max-width: 95%;
  --max-height: 95%;

  &.add-course-modal {
    --width: 45rem;
    --height: 60rem;
  }

  &.change-year-modal {
    --width: 40rem;
    --height: 20rem;
  }

  &.confirm-modal {
    --width: 60rem;
    --height: 18rem;
  }

  &.submit-proposal-modal {
    --width: 65rem;
    --height: 70rem;
  }

  &.course-info-modal {
    --width: 80rem; // for supporting 6 periods per year on Safari; other browsers need less width
    --height: 60rem;
  }

  &.test-info-modal {
    --width: 60rem;
    --height: 10rem;
  }

  &.new-draft-modal {
    --width: 40rem;
    --height: 50rem;
  }

  &.rename-draft-modal {
    --width: 40rem;
    --height: 18rem;
  }

  &.show-history-modal {
    --width: 60rem;
    --height: 70rem;
  }

  &.schedule-course-modal {
    // width is fit-content for supporting both a few periods and up to 6 periods
    --width: fit-content;
    --height: fit-content;
  }

  &.flexible-plan-modal {
    --width: 40rem;
    --height: 19rem;
  }
}

ion-popover {
  --width: fit-content;
  --min-width: 20rem;
}

ion-button {
  white-space: nowrap;
}

// Styling of focus ring when tabbing through elements using keyboard, copied from Osiris Student.
// More info: https://caci-nl.atlassian.net/wiki/spaces/PO/pages/16860414082/Osiris+Student+standaarden+en+richtlijnen#Focus-ring-voor-toegankelijkheid/accessibility
// Not applied to input components, because PlanApp stays closer to the Ionic properties which already have their own focus styling.
button:focus-visible,
ion-button.ion-focused,
ion-segment-button.ion-focused,
ion-fab.ion-focused,
.course-card:focus,
ion-label.ion-focused {
  border: 1px solid black;
  outline: 2px solid white;
  box-shadow: 0 0 8px 1px white;
}

ion-radio {
  --color-checked: var(--pa-color-green);

  &.radio-disabled::part(label) {
    opacity: var(--pa-opacity-disabled);
  }
}
